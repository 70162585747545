// 
// 
// user.scss
//
// Place your own sparrow CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

.indicator-custom-float {
	z-index: 1050;
}

.indicator {
  display: none;
  position: absolute;
  height: 3rem;
  width: 3rem;
  left: 50%;
}

@media (min-width: 1200px) {
  .indicator {
    display: block;
    position: absolute;
    height: 3rem;
    width: 3rem;
    left: 50%;
  }
}

@media (max-width: 1199.98px) {
.portfolio-index {
    padding-top: 0 !important;
}
}